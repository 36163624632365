import { default as calendar25xjPmDhyRMeta } from "/opt/buildhome/repo/pages/account/calendar.vue?macro=true";
import { default as change_45password25pXFeMibUMeta } from "/opt/buildhome/repo/pages/account/change-password.vue?macro=true";
import { default as editISJQ4dSGDGMeta } from "/opt/buildhome/repo/pages/account/edit.vue?macro=true";
import { default as accountFCGaMrzILUMeta } from "/opt/buildhome/repo/pages/account.vue?macro=true";
import { default as forgot_45passwordUaF9oDbyV0Meta } from "/opt/buildhome/repo/pages/auth/forgot-password.vue?macro=true";
import { default as loginLsrADI0A5CMeta } from "/opt/buildhome/repo/pages/auth/login.vue?macro=true";
import { default as registerPJjcn4pY50Meta } from "/opt/buildhome/repo/pages/auth/register.vue?macro=true";
import { default as reset_45passwordh7H23OMrjXMeta } from "/opt/buildhome/repo/pages/auth/reset-password.vue?macro=true";
import { default as classroomUiwEhIIlFfMeta } from "/opt/buildhome/repo/pages/classroom.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as buy5kr1k554bwMeta } from "/opt/buildhome/repo/pages/packages/[id]/buy.vue?macro=true";
import { default as alerteayZSxt5UpMeta } from "/opt/buildhome/repo/pages/packages/alert.vue?macro=true";
import { default as index5VRbPnH7VyMeta } from "/opt/buildhome/repo/pages/packages/index.vue?macro=true";
import { default as _91status_93bFRWlZ1BSUMeta } from "/opt/buildhome/repo/pages/payment/[status].vue?macro=true";
import { default as ostatus8a2Er6o4m9Meta } from "/opt/buildhome/repo/pages/payment/ostatus.vue?macro=true";
import { default as _91id_93qlse8JwSUoMeta } from "/opt/buildhome/repo/pages/tickets/[id].vue?macro=true";
import { default as createehEYQtrFACMeta } from "/opt/buildhome/repo/pages/tickets/create.vue?macro=true";
import { default as indexJNqzFKHVPaMeta } from "/opt/buildhome/repo/pages/tickets/index.vue?macro=true";
export default [
  {
    name: "account",
    path: "/account",
    meta: accountFCGaMrzILUMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account.vue"),
    children: [
  {
    name: "account-calendar",
    path: "calendar",
    component: () => import("/opt/buildhome/repo/pages/account/calendar.vue")
  },
  {
    name: "account-change-password",
    path: "change-password",
    component: () => import("/opt/buildhome/repo/pages/account/change-password.vue")
  },
  {
    name: "account-edit",
    path: "edit",
    component: () => import("/opt/buildhome/repo/pages/account/edit.vue")
  }
]
  },
  {
    name: "auth-forgot-password",
    path: "/auth/forgot-password",
    meta: forgot_45passwordUaF9oDbyV0Meta || {},
    component: () => import("/opt/buildhome/repo/pages/auth/forgot-password.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginLsrADI0A5CMeta || {},
    component: () => import("/opt/buildhome/repo/pages/auth/login.vue")
  },
  {
    name: "auth-register",
    path: "/auth/register",
    meta: registerPJjcn4pY50Meta || {},
    component: () => import("/opt/buildhome/repo/pages/auth/register.vue")
  },
  {
    name: "auth-reset-password",
    path: "/auth/reset-password",
    meta: reset_45passwordh7H23OMrjXMeta || {},
    component: () => import("/opt/buildhome/repo/pages/auth/reset-password.vue")
  },
  {
    name: "classroom",
    path: "/classroom",
    meta: classroomUiwEhIIlFfMeta || {},
    component: () => import("/opt/buildhome/repo/pages/classroom.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexniDiYCWjuTMeta || {},
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "packages-id-buy",
    path: "/packages/:id()/buy",
    component: () => import("/opt/buildhome/repo/pages/packages/[id]/buy.vue")
  },
  {
    name: "packages-alert",
    path: "/packages/alert",
    component: () => import("/opt/buildhome/repo/pages/packages/alert.vue")
  },
  {
    name: "packages",
    path: "/packages",
    component: () => import("/opt/buildhome/repo/pages/packages/index.vue")
  },
  {
    name: "payment-status",
    path: "/payment/:status()",
    component: () => import("/opt/buildhome/repo/pages/payment/[status].vue")
  },
  {
    name: "payment-ostatus",
    path: "/payment/ostatus",
    component: () => import("/opt/buildhome/repo/pages/payment/ostatus.vue")
  },
  {
    name: "tickets-id",
    path: "/tickets/:id()",
    meta: _91id_93qlse8JwSUoMeta || {},
    component: () => import("/opt/buildhome/repo/pages/tickets/[id].vue")
  },
  {
    name: "tickets-create",
    path: "/tickets/create",
    meta: createehEYQtrFACMeta || {},
    component: () => import("/opt/buildhome/repo/pages/tickets/create.vue")
  },
  {
    name: "tickets",
    path: "/tickets",
    meta: indexJNqzFKHVPaMeta || {},
    component: () => import("/opt/buildhome/repo/pages/tickets/index.vue")
  }
]